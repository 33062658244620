/**
 * @param {string} url
 * @returns {Boolean}
 */
export const validURL = (url: string): boolean => {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validLowerCase = (str: string): boolean => {
  const reg = /^[a-z]+$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validUpperCase = (str: string): boolean => {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validAlphabets = (str: string): boolean => {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validKoreanLanguage = (str: string): boolean => {
  const reg =
    /^[ㄱ-ㅎ가-힣ㅏ-ㅣ\u318D\u119E\u11A2\u2022\u2025\u00B7\uFE55\u4E10\u3163\u3161]+$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 * 공백 여부 확인
 */
export const validIsSpase = (str: string): boolean => {
  return str.search(/\s/) !== -1;
};

/**
 * @param {string} email
 * @returns {Boolean}
 */
export const validEmail = (email: string): boolean => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const checkValidPhoneNumber = (str: string): boolean => {
  const reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const checkValidAccountNumber = (str: string): boolean => {
  return str.length > 9;
};

/**
 *
 * @param {string }str
 * @returns {Boolean}
 */
export const checkValidKakaoId = (str: string): boolean => {
  const reg = /^[a-zA-Z0-9-_.]*$/;
  return reg.test(str);
};

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validPassword = (str: string): boolean => {
  let isValidCount = 0;

  const regUpper = /(?=.*?[AZ])/;
  const regNumber = /(?=.*?[0-9])/;
  const regSpecial = /(?=.*?[#?!@$%^&*-])/;
  const regLength = /(?=^.{6,16}$)/;

  isValidCount += regUpper.test(str) ? 1 : 0;
  isValidCount += regNumber.test(str) ? 1 : 0;
  isValidCount += regSpecial.test(str) ? 1 : 0;

  return regLength.test(str) && isValidCount >= 2;
};

/** string이 유효한 날짜인지 검색 */
export const isValidDate = (str: string): boolean => {
  // yyyy-MM-dd hh:mm:ss
  const regExp =
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/;
  return regExp.test(str);
};
