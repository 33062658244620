import React, { ReactElement } from 'react';

import { getElapsedTime, getWonUnit } from '@common/parser';
import { isValidDate } from '@common/validate';
import { css } from '@emotion/react';
import { ProductsType } from '@type/web/product';

import ThumbnailImage from '@components/common/Image/ThumbnailImage';
import Tag from '@components/common/Tag';

import { palette } from '../../../../lib/styles/palette';

interface ProductItemVideoProps {
  product: Partial<ProductsType> | undefined;
}

function ProductItemVideo({ product }: ProductItemVideoProps): ReactElement {
  /** 스켈레톤 추가 예정 */
  if (!product) return <></>;

  const {
    url = '',
    title = '',
    price = 0,
    mainLocationName = '',
    sortDate = '',
  } = product;
  return (
    <div css={wrap}>
      <ThumbnailImage
        className="video"
        src={url}
        alt={title}
        isSquare={false}
      />
      <div className="info">
        <Tag title="PLAY" className="tag" />
        <div className="bottom">
          <p className="price">{getWonUnit(price)}</p>
          <p className="title">{title}</p>
          <p className="etc">
            {mainLocationName && <span>{mainLocationName}</span>}
            {isValidDate(sortDate) && <span>{getElapsedTime(sortDate)}</span>}
          </p>
        </div>
      </div>
    </div>
  );
}

const wrap = css`
  padding-top: 150%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .tag {
      height: 22px;
      margin-top: 16px;
      margin-left: 16px;
      font-weight: 700;
      color: ${palette.white};
      background: linear-gradient(90deg, #ff6900 -12.5%, #ffb800 125%);
    }
    .bottom {
      width: 100%;
      padding: 20px 16px 16px 16px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.5) 64.06%,
        rgba(0, 0, 0, 0) 100%
      );
      color: ${palette.white};
      .price {
        font-size: 14px;
        font-weight: 700;
      }
      .title {
        height: 36px;
        overflow: hidden;
        margin-top: 8px;
        line-height: 18px;
        font-size: 14px;
        word-break: break-all;
      }
      .etc {
        margin-top: 8px;
        font-size: 12px;
        color: ${palette.gray5};
        span {
          padding-right: 6px;
          position: relative;
          &:not(:first-of-type) {
            padding-left: 6px;
            &:before {
              content: '';
              height: 10px;
              margin: auto;
              padding-left: 6px;
              border-left: 1px solid ${palette.gray5};
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }
`;

export default ProductItemVideo;
