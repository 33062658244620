import { DESKTOP_MEDIA } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

export default function SkeletonProductItemGallery() {
  return (
    <div css={skeletonStyle}>
      <div className="thumbWrap" />
      <div className="priceTxt" />
      <div className="titleTxt" />
      <div className="registInfo" />
      <div className="bottomInfo">
        <span className="payBadge" />
      </div>
    </div>
  );
}

const skeletonStyle = css`
  width: 100%;
  margin-bottom: 25px;
  .thumbWrap {
    width: 100%;
    padding-top: 100%;
    background-color: ${palette.gray0};
    border-radius: 5px;
  }
  .priceTxt {
    height: 1rem;
    margin-top: 12px;
    width: 50%;
  }
  .registInfo {
    height: 15px;
    width: 80%;
    margin: 6px 0 2px;
  }
  .titleTxt {
    margin-top: 8px;
    width: 100%;
    height: 34px;
  }

  .priceTxt,
  .registInfo,
  .titleTxt {
    background-color: ${palette.gray0};
  }
  .bottomInfo {
    width: 100%;
    height: 20px;
    .payBadge {
      display: block;
      background-color: ${palette.gray0};
      height: 17px;
      width: 30px;
      border-radius: 4px;
    }
  }
`;
