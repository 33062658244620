import React, { ReactElement } from 'react';

import { PRODUCT_STATUS, PRODUCT_STATUS_KOR } from '@common/const';
import { getElapsedTime, getWonUnit } from '@common/parser';
import { isUnavailableForSale } from '@common/product';
import { isValidDate } from '@common/validate';
import { css } from '@emotion/react';
import { ProductStatusType, ProductsType } from '@type/web/product';

import Icon from '@components/common/Icon/Icon';
import ThumbnailImage from '@components/common/Image/ThumbnailImage';
import SkeletonProductItemGallery from '@components/common/Skeleton/Product/SkeletonProductItemGallery';

import TextEllipsisSpan from '../../../../lib/styles/TextEllipsisSpan';
import { palette } from '../../../../lib/styles/palette';

interface ProductItemGalleryProps {
  product: Partial<ProductsType> | undefined;
  highlightedTitle?: string;
}

function ProductItemGallery({
  product,
  highlightedTitle,
}: ProductItemGalleryProps): ReactElement {
  /** 스켈레톤 */
  if (!product) return <SkeletonProductItemGallery />;

  const {
    seq,
    url = '',
    articleSeq,
    articleUrl,
    title = '',
    price = 0,
    mainLocationName,
    sortDate = '',
    state: status = 0,
    videoUrl,
    videoProductYn,
    jnPayBadgeFlag,
  } = product;

  const isVideoProduct = videoProductYn === 'Y';

  return (
    <div css={productItem}>
      <div className="thumbWrap">
        <ThumbnailImage src={url} alt={title} />
        {isVideoProduct && (
          <Icon className="playIcon" icon="playVideo" size={36} />
        )}
        {isUnavailableForSale(status) && (
          <div css={statusCoverStyle}>
            <div css={getStatusStyle(status)}>{PRODUCT_STATUS_KOR[status]}</div>
          </div>
        )}
      </div>
      <div className="priceTxt">{getWonUnit(price)}</div>
      {highlightedTitle ? (
        <div
          className="titleTxt"
          dangerouslySetInnerHTML={{ __html: highlightedTitle }}
        />
      ) : (
        <div className="titleTxt">
          <TextEllipsisSpan>{title}</TextEllipsisSpan>
        </div>
      )}
      <div className="registInfo">
        {mainLocationName && <span>{mainLocationName}</span>}
        {mainLocationName && <div className="divider" />}
        {isValidDate(sortDate) && <span>{getElapsedTime(sortDate)}</span>}
      </div>
      <div className="bottomInfo">
        {jnPayBadgeFlag && <Icon icon="payBadge" />}
        <div>{/* 댓글 / 좋아요 영역 */}</div>
      </div>
    </div>
  );
}

const productItem = css`
  color: ${palette.black10};
  margin-bottom: 25px;
  .thumbWrap {
    position: relative;

    .playIcon {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }
  }

  .priceTxt {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
  }

  .titleTxt {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 17px;
    height: 34px;
    em {
      color: ${palette.orange9};
      font-style: normal;
    }
  }

  .registInfo {
    display: flex;
    align-items: baseline;
    margin: 6px 0 2px;
    font-size: 12px;
    color: ${palette.gray5};
    line-height: normal;

    .divider {
      width: 1px;
      height: 10px;
      background-color: ${palette.gray4};
      margin: 0 6px;
    }
  }
  .bottomInfo {
    height: 20px;
    display: flex;
    align-items: center;
  }
`;

const statusCoverStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
`;

const getStatusStyle = (status: ProductStatusType) => css`
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  ${status !== PRODUCT_STATUS.ON_SALE &&
  `background-color: ${
    (status === PRODUCT_STATUS.SOLD_OUT && 'rgba(0, 0, 0, 0.5)') ||
    palette.orange9
  }`};
  color: ${palette.white};
`;

export default React.memo(ProductItemGallery);
