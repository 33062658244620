import React, { ReactElement } from 'react';

import { ProductItemType, ProductsType } from '@type/web/product';

import ProductItemGallery from './ProductItemGallery';
import ProductItemList from './ProductItemList';
import ProductItemVideo from './ProductItemVideo';

interface ProductItemProps {
  type: keyof ProductItemType;
  product: Partial<ProductsType> | undefined;
  priority?: boolean;
}

function ProductItem({
  type,
  product,
  priority,
}: ProductItemProps): ReactElement {
  switch (type) {
    case 'LIST':
      return <ProductItemList product={product} priority={priority} />;
    case 'VIDEO':
      return <ProductItemVideo product={product} />;
    default:
      return <ProductItemGallery product={product} />;
  }
}

export default ProductItem;
