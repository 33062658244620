import React, { Fragment, ReactElement } from 'react';

import { Row, Col } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

import { PLATFORM_TYPE } from '@common/const';
import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ProductsType, ProductItemType } from '@type/web/product';
import { AdsInnerListItem } from '@type/web/search';

import useResponsive from '@lib/hook/useResponsive';

import Button from '@components/common/Button/Button';
import ProductItem from '@components/common/ProductItem';
import AdsItem from '@components/common/ProductItem/ProductItemGallery/AdsItem';
import SkeletonProductListTypeGallery from '@components/common/Skeleton/Product/SkeletonProductListTypeGallery';

type flexItemsSizeType = {
  MOBILE?: string;
  TABLET?: string;
  PC?: string;
};

interface ProductListTypeGalleryProps {
  products: Partial<ProductsType>[];
  ads?: Array<AdsInnerListItem>;
  productType?: keyof ProductItemType;
  length?: number;
  gutter?: Gutter | [Gutter, Gutter];
  flexItemsSize?: flexItemsSizeType;
  name: string;
  className?: string;
  priority?: boolean;
}

type AdsItemPositionsType = {
  [key: string]: number;
};
const ProductListTypeGallery = ({
  products,
  length = 20,
  productType,
  gutter = [
    { xs: 16, sm: 16, md: 16, lg: 20 },
    { xs: 24, sm: 24, md: 24, lg: 10 },
  ],
  flexItemsSize,
  name,
  className,
  priority,
  ads,
}: ProductListTypeGalleryProps): ReactElement => {
  const { isPC } = useResponsive();
  /** 스켈레톤 */
  if (!products.length)
    return (
      <SkeletonProductListTypeGallery
        css={wrap}
        className={className}
        gutter={gutter}
        StyledCol={StyledCol}
        flexItemsSize={flexItemsSize}
        productType={productType}
        length={length}
      />
    );
  /**
   * 상품 리스트 Index 기준 광고 위치 : 데스크탑 (상품 80개) / 모바일 (상품 20개)
   * 상품 리스트 80개 일때 최대 광고 수 8개
   * 상품 리스트 20개 일때 최대 광고 수 4개
   */
  const adsPositions = isPC ? [3, 10, 25, 36, 47, 58, 69, 76] : [3, 8, 13, 18];

  /**
   * adsItemPoisitions: 상품 리스트 Index와 광고 리스트 Index Map
   * adsItemPositions = {상품리스트 Index: 광고리스트 Index, ...}
   * 예)
   * adsItemPositions = {3: 0, 10: 1, 25: 2, 36: 3, 47: 4, 58: 5, 69: 6, 76: 7}
   *
   * ads[adsItemPositions[10]]
   * adsItemPosition[10] = 1
   * ads[1] = 광고 리스트 중 2번쨰 광고 (index: 1)
   */
  const adsItemPositions: AdsItemPositionsType = {};
  adsPositions?.forEach((item, idx) => {
    adsItemPositions[`${item}`] = idx;
  });

  return (
    <Row css={wrap} className={className} gutter={gutter}>
      {products.map((product: Partial<ProductsType>, idx) => {
        const { seq, articleSeq, articleUrl, platformType } = product;
        const isCafeProduct = platformType === PLATFORM_TYPE.CAFE;
        const detailUrl = `/product/${
          isCafeProduct ? `detail/naver/${articleSeq}` : seq
        }`;
        const detailAsUrl = isCafeProduct
          ? `/product/detail/naver/${articleSeq}`
          : undefined;
        if (
          ads &&
          adsPositions?.find((item) => item === idx) &&
          ads[adsItemPositions[`${idx}`]]
        ) {
          return (
            <Fragment
              key={`${name}_${ads[adsItemPositions[`${idx}`]].productName}`}
            >
              {ads[adsItemPositions[`${idx}`]] && (
                <StyledCol flexItemsSize={flexItemsSize} className="col">
                  <AdsItem adItemInfo={ads[adsItemPositions[`${idx}`]]} />
                </StyledCol>
              )}
              <StyledCol flexItemsSize={flexItemsSize} className="col">
                <Button
                  type="link"
                  padding="0"
                  to={detailUrl}
                  as={detailAsUrl}
                  data-seq={isCafeProduct ? articleSeq : ''}
                  data-url={articleUrl}
                >
                  <ProductItem
                    type={productType || 'GALLERY'}
                    product={product}
                    priority={priority}
                  />
                </Button>
              </StyledCol>
            </Fragment>
          );
        }
        return (
          <StyledCol
            key={`${name}_${seq || articleSeq}`}
            flexItemsSize={flexItemsSize}
            className="col"
          >
            <Button
              type="link"
              padding="0"
              to={detailUrl}
              as={detailAsUrl}
              data-seq={isCafeProduct ? articleSeq : ''}
              data-url={articleUrl}
            >
              <ProductItem
                type={productType || 'GALLERY'}
                product={product}
                priority={priority}
              />
            </Button>
          </StyledCol>
        );
      })}
    </Row>
  );
};

const wrap = css`
  padding: 28px 0 8px;
  .col {
    > a {
      display: block;
    }
  }
`;

const StyledCol = styled(Col)(
  ({ flexItemsSize }: { flexItemsSize?: flexItemsSizeType }) => css`
    flex-basis: ${flexItemsSize?.PC || '20%'};

    @media (max-width: ${TABLET_WIDTH}) {
      flex-basis: ${flexItemsSize?.TABLET || '25%'};
    }

    @media (max-width: ${MOBILE_WIDTH}) {
      flex-basis: ${flexItemsSize?.MOBILE || '50%'};
    }
  `,
);

export default React.memo(ProductListTypeGallery);
