import React from 'react';

import { getWonUnit } from '@common/parser';
import { css } from '@emotion/react';
import { AdsInnerListItem } from '@type/web/search';

import TextEllipsisSpan from '@lib/styles/TextEllipsisSpan';
import { palette } from '@lib/styles/palette';

import ThumbnailImage from '@components/common/Image/ThumbnailImage';

interface AdsItemProp {
  adItemInfo: AdsInnerListItem;
}
const AdsItem = ({ adItemInfo }: AdsItemProp): JSX.Element => {
  const {
    productImage,
    productName,
    productPrice,
    landingUrl,
    mallName,
    naverPayIconInfo,
  } = adItemInfo;
  return (
    <a href={landingUrl} target="_blank" css={adItemWrapper} rel="noreferrer">
      <div className="thumbWrap">
        <ThumbnailImage src={productImage} alt={productName} />
      </div>
      <div className="priceTxt">{getWonUnit(productPrice)}</div>
      <div className="titleTxt">
        <TextEllipsisSpan>{productName}</TextEllipsisSpan>
      </div>
      <div className="registInfo">
        {mallName && <span>{mallName}</span>}
        {mallName && <div className="divider" />}
        <span>광고</span>
      </div>
      <div className="bottomInfo">
        {naverPayIconInfo && (
          <img
            src={naverPayIconInfo.url}
            alt="네이버페이"
            height={naverPayIconInfo.height}
            width={naverPayIconInfo.width}
          />
        )}
      </div>
    </a>
  );
};

export default AdsItem;

const adItemWrapper = css`
  color: ${palette.black10};
  margin-bottom: 25px;
  .thumbWrap {
    position: relative;

    .adLabel {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 4px 6px 2px 6px;
      border-radius: 4px;
      letter-spacing: 0.5px;
    }
  }

  .priceTxt {
    padding-top: 12px;
    font-size: 16px;
    font-weight: 600;
  }

  .titleTxt {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    word-break: break-all;
    line-height: 17px;

    em {
      color: ${palette.orange9};
      font-style: normal;
    }
  }

  .registInfo {
    display: flex;
    align-items: baseline;
    margin: 6px 0 2px;
    font-size: 12px;
    color: ${palette.gray5};
    line-height: normal;

    .divider {
      width: 1px;
      height: 10px;
      background-color: ${palette.gray4};
      margin: 0 6px;
    }
  }
  .bottomInfo {
    height: 20px;
    display: flex;
    align-items: center;
  }
`;
