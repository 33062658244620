import React, { ReactElement } from 'react';

import { PRODUCT_STATUS, PRODUCT_STATUS_KOR } from '@common/const';
import { getWonUnit, getElapsedTime } from '@common/parser';
import { isUnavailableForSale } from '@common/product';
import { css } from '@emotion/react';
import { ProductStatusType, ProductsType } from '@type/web/product';

import Icon from '@components/common/Icon/Icon';
import ThumbnailImage from '@components/common/Image/ThumbnailImage';

import { palette } from '../../../../lib/styles/palette';

interface ProductItemListProps {
  product: Partial<ProductsType> | undefined;
  priority?: boolean;
}

const ProductItemList = ({
  product,
  priority,
}: ProductItemListProps): ReactElement => {
  /** 스켈레톤 추가 예정 */
  if (!product) return <></>;

  const {
    url = '',
    title = '',
    highlightedTitle = '',
    price = 0,
    mainLocationName = '',
    sortDate = '',
    state: status = 0,
    videoUrl = '',
    videoProductYn,
    jnPayBadgeFlag,
  } = product;

  const isVideoProduct = videoProductYn === 'Y';

  return (
    <div css={productWrap}>
      <div className="thumbWrap">
        <ThumbnailImage src={url} alt={title} priority={priority} />
        {isVideoProduct && (
          <Icon className="playIcon" icon="playVideo" size={32} />
        )}
        {isUnavailableForSale(status) && (
          <div css={statusCoverStyle}>
            <div css={getStatusStyle(status)}>{PRODUCT_STATUS_KOR[status]}</div>
          </div>
        )}
      </div>
      <div className="info">
        <div className="price">{getWonUnit(price)}</div>
        {highlightedTitle ? (
          <p
            className="title"
            dangerouslySetInnerHTML={{ __html: highlightedTitle }}
          />
        ) : (
          <p className="title">{title}</p>
        )}
        <div className="registInfo">
          {mainLocationName && <span>{mainLocationName}</span>}
          <span>{getElapsedTime(sortDate)}</span>
        </div>
        <div className="bottomInfo">
          {jnPayBadgeFlag && <Icon icon="payBadge" />}
          <div>{/* 댓글 / 좋아요 영역 */}</div>
        </div>
      </div>
    </div>
  );
};

const productWrap = css`
  display: flex;
  flex-wrap: wrap;

  .thumbWrap {
    width: 128px;
    position: relative;

    .playIcon {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }
  }

  .info {
    flex: 1;
    padding: 4px 16px 0;
    color: ${palette.black10};

    .price {
      font-size: 16px;
      font-weight: 600;
    }
    .title {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;

      em {
        color: ${palette.orange9};
        font-style: normal;
      }
    }
    .registInfo {
      margin-top: 8px;
      font-size: 12px;
      color: ${palette.gray5};

      span:not(:first-of-type) {
        margin-left: 4px;

        &:before {
          content: '|';
          margin-right: 4px;
        }
      }
    }
    .bottomInfo {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }
`;

const statusCoverStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
`;

const getStatusStyle = (status: ProductStatusType) => css`
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  ${status !== PRODUCT_STATUS.ON_SALE &&
  `background-color: ${
    (status === PRODUCT_STATUS.SOLD_OUT && 'rgba(0, 0, 0, 0.5)') ||
    palette.orange9
  }`};
  color: ${palette.white};
`;

export default ProductItemList;
