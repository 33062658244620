import { isDesktop } from 'react-device-detect';

import { Row } from 'antd';

import ProductItem from '@components/common/ProductItem';

export default function SkeletonProductListTypeGallery({
  css,
  className,
  gutter,
  StyledCol,
  flexItemsSize,
  productType,
  length,
}: any) {
  return (
    <Row css={css} className={className} gutter={gutter}>
      {Array(isDesktop ? 80 : 20)
        .fill(null)
        .map((v, i) => (
          <StyledCol key={i} flexItemsSize={flexItemsSize} className="col">
            <ProductItem type={productType || 'GALLERY'} product={undefined} />
          </StyledCol>
        ))}
    </Row>
  );
}
