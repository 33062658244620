import React, { ReactElement, MouseEvent } from 'react';

import { css } from '@emotion/react';

import useRouterHook from '@lib/hook/useRouterHook';

import { palette } from '../../../lib/styles/palette';
import Button from '../Button';
import Icon from '../Icon';

type TagType = 'primary' | 'secondary';
type TagShape = 'square' | 'round';
interface TagProps {
  type?: TagType;
  shape?: TagShape;
  title: string;
  isDeleteMode?: boolean;
  className?: string;
  to?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onClose?: () => void;
}

function Tag({
  type = 'primary',
  shape = 'round',
  title,
  isDeleteMode = false,
  className = '',
  to = '',
  onClick,
  onClose,
}: TagProps): ReactElement {
  const iconColor = {
    color: type === 'primary' ? '#FFF' : palette.black10,
    stroke: type === 'primary' ? '#ff6900' : '#FFF',
  };
  const { push } = useRouterHook();
  const onClickHandler = (e: MouseEvent<HTMLElement>) => {
    if (onClick) onClick(e);
    push(to);
  };
  if (to) {
    return (
      <button
        type="button"
        aria-label="delete"
        css={wrap(type)}
        onClick={onClickHandler}
        className={className}
      >
        {title}
        {isDeleteMode && <Icon icon="xCircleFill" size={16} {...iconColor} />}
      </button>
    );
  }
  return (
    <Button
      css={wrap(type)}
      className={className}
      type="light"
      shape={shape}
      size="small"
      padding={shape === 'square' ? '10px 16px' : ''}
      onClick={onClose}
      rightIcon={
        onClose && <Icon icon="xCircleFill" size={16} {...iconColor} />
      }
    >
      {title}
    </Button>
  );
}

const tagColor = (type: TagType) => {
  switch (type) {
    case 'secondary':
      return css`
        background-color: #f5f5f5;
        border: none;
        color: #333;
      `;
    case 'primary':
    default:
      return css`
        background-color: #ff6900;
        border: none;
        color: #eee;
      `;
  }
};

const wrap = (type: TagType) => css`
  ${tagColor(type)}
  cursor: default;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  color: ${palette.gray6};
  /* box-sizing: border-box; */

  :hover {
    color: ${palette.gray6};
  }
  svg {
    opacity: 0.6;
  }
`;

export default Tag;
